<template>
  <div>
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Products In Bin')}}</h2>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadProductInBin"></head-component>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="products"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('WRO')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('Inbounded QTY')}}</shipblu-th>
        <shipblu-th>{{$t('Stored QTY')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].wro">
            {{ data[indextr].product_in_stock_request.stock_request }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku">
            {{ data[indextr].product_in_stock_request.product.sku }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfPackages">
            {{ data[indextr].product_in_stock_request.inbounded_quantity }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].numberOfPackages">
            {{ data[indextr].product_in_stock_request.stored_quantity }}
          </shipblu-td>

          <shipblu-td>
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openProductStore(data[indextr])"
                  >
                    <span class="flex gap-2 items-center">
                      <feather-icon
                        icon="BoxIcon"
                        svgClasses="h-4 w-4"
                      />
                      <span>{{$t('Store SKU')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <store-product-modal :storeProductModal="storeProductModal" @storeProductModal="storeProductModal = $event" :storageData="storageData" @loadStockProducts="loadProductInBin"/>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import StoreProductModal from './components/StoreProductModal.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'
import common from '@/assets/utils/common'

export default {
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    StoreProductModal,
    ShipbluPagination,
    HeadComponent
  },
  data () {
    return {
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Inbounded',
          value: 'inbounded'
        },
        {
          name: 'Stored',
          value: 'stored'
        }
      ],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      selected: [],
      currentPage: (this.$route.query.page && Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      storageData: {
        product_in_stock_request: {
          product: {}
        }
      },
      tableLoader: false,
      storeProductModal: false,
      products: [],
      searchVal: '',
      searchedValue: ' ',
      searchInProgress: false,
      filters: '',
      filterValue: ''
    }
  },
  methods: {
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: 1,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProductInBin()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadBinSearch)
    },
    loadBinSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadProductInBin()
    },
    loadProductInBin () {
      this.searchInProgress = true
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/products-in-bin/?bin=${this.$route.params.binID}&limit=${this.maximumItems}&offset=${this.offset}&product_sku=${this.searchVal}&${this.filters === 'inbounded' ? 'inbounding_quantity__gt=0' : this.filters === 'stored' ? 'inbounding_quantity=0' : '' }`, 'get', null, true,
        (response) => {
          this.products = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    openProductStore (data) {
      this.storageData = {
        ...data,
        inbounded_quantity: 0
      }
      this.storeProductModal = true
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProductInBin()
    }
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadProductInBin()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>