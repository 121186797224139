<template>
  <shipblu-prompt
    class="shipment-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="storeProductModal"
    :title="$t('Store Product')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-4">
      <div class="sm:col-span-2 col-span-1">
        <v-select disabled class="input-customer-info-filled remove-search w-full input-customer-info product-dropdown" 
          autocomplete="nofill" label="sku" :name="'Sku code'" v-model="storageData.product_in_stock_request.product.sku" v-validate="'required'" 
          :placeholder="$t('Sku code') + '*'" :options="productCatalog"/>
        <span class="text-danger text-sm" v-show="errors.has('Sku code')">{{ errors.first('Sku code') }}</span>
      </div>
      <div class="sm:col-span-3 col-span-1">
        <vs-input disabled :class="storageData.product_in_stock_request.stock_request ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
         autocomplete="nofill" :name="'flags'" v-model="storageData.product_in_stock_request.stock_request" :placeholder="$t('WRO')"/>
      </div>
      <div class="md:col-span-2 col-span-5 flex items-center w-full justify-between">
        <div class="md:block flex items-center">
          <p class="text-sm text-lightgray mr-1">{{ $t('Stored Quantity') }}:</p>
          <div>
            <vs-input-number min="0" :max="storageData.product_in_stock_request.inbounded_quantity" v-validate="'required|numeric'" name="quantity" v-model="storageData.product_in_stock_request.inbounded_quantity" class="stock-request-input-number flex justify-start"/>
            <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-4">
      <p class="mt-10 font-medium text-lg">{{$t('Storage Units')}}</p>
      <div v-for="(item, index) in storageUnits " :key="index">
        <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-4 mb-6">
          <div class="sm:col-span-2 col-span-1">
            <v-select  @search="handleStorageUnitSearch" @keyup.enter.native="handleStorageUnitSearch" search :class="item.storage ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" label="label" :name="'Storage Unit'" v-model="item.storage" v-validate="'required'" 
              :placeholder="$t('Storage Unit') + '*'" :options="storageUnitsOptions"/>
            <span class="text-danger text-sm" v-show="errors.has('Storage Unit')">{{ errors.first('Storage Unit') }}</span>
          </div>
          <div class="md:col-span-2 col-span-5 flex items-center w-full justify-between">
            <div class="md:block flex items-center">
              <p class="text-xs text-lightgray mr-1">{{ $t('Stored Quantity') }}:</p>
              <div>
                <vs-input-number min="0" :class="(storageData.product_in_stock_request.inbounded_quantity- totalInboundedQuantity === 0) ? 'input-number-disabled' : ''" :max="storageData.product_in_stock_request.inbounded_quantity" v-validate="'required|numeric'" name="quantity" v-model="item.inbounded_quantity" class="stock-request-input-number flex justify-start"/>
                <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
              </div>
            </div>
            <span v-if="storageUnits.length > 1" @click="removeStorageUnits(index)" class="remove-package-icon cursor-pointer mb-1">
              <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
            </span>
          </div>
        </div>
      </div>
      <div v-if="addMoreStorage" class="relative flex items-center justify-end mt-8">
        <div class="absolute add-products">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more Storage Units')}}</span>
        </div>
        <div class="p-1 flex self-center bg-primary rounded">
          <feather-icon @click="addStorage()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button :disabled="addMoreStorage" :class="addMoreStorage ? 'disable-btn' : 'active-btn'" @click="storeProduct" class="btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common from '../../../assets/utils/common'

export default {
  props: ['storeProductModal', 'storageData'],
  data () {
    return {
      productCatalog: [],
      storageUnits: [
        {
          bin: '',
          inbounded_quantity: 0
        }
      ],
      totalInboundedQuantity: 0,
      addMoreStorage: true,
      storageUnitsOptions: [],
      searchVal: ''
    }
  },
  watch: {
    'storeProductModal' (val) {
      if (val === true) {
        this.loadStorageUnits()
      }
    },
    'storageData.product_in_stock_request.inbounded_quantity' (val) {
      this.storageUnits = []
      this.storageUnits.push(
        {
          bin: '',
          inbounded_quantity: this.storageData.product_in_stock_request.received_quantity
        }
      )
      this.storageUnits[0].inbounded_quantity = val
    },
    'storageUnits': {
      handler (val) {
        this.totalInboundedQuantity = 0
        val.forEach(item => {
          this.totalInboundedQuantity += Number(item.inbounded_quantity)
        })
        if (this.totalInboundedQuantity >= this.storageData.product_in_stock_request.inbounded_quantity) {
          this.addMoreStorage = false
        } else {
          this.addMoreStorage = true
        }
      },
      deep: true
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  methods: {
    handleStorageUnitSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStorageUnitsSearch)
    },
    loadStorageUnitsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadStorageUnits()
      }
    },
    loadStorageUnits () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/storage-units/?product_sku=${this.storageData.sku}&ops_id=${this.searchVal}&limit=100`, 'get', null, true,
        (response) => {
          this.storageUnitsOptions = response.data.results
          this.storageUnitsOptions.forEach(item => {
            item.label = `${item.ops_id} (stored: ${item.product_sku_quantity})`
          })
          if (this.storageUnitsOptions.length === 0)  {
            sendFulfillmentRequest(false, false, this, `api/v1/fc/storage-units/?ops_id=${this.searchVal}&limit=100`, 'get', null, true,
              (response) => {
                this.storageUnitsOptions = response.data.results
                this.storageUnitsOptions.forEach(item => {
                  item.label = item.ops_id
                })
              }
            )
          }
        }
      )
    },
    removeStorageUnits (index) {
      this.storageUnits.splice(index, 1)
    },
    addStorage () {
      this.storageUnits.push({
        bin: '',
        inbounded_quantity: this.storageData.product_in_stock_request.inbounded_quantity - this.totalInboundedQuantity
      })
    },
    closeModal () {
      this.$emit('storeProductModal', false)
      this.$emit('loadStockProducts')
    },
    storeProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const binsInStorage = []
          this.storageUnits.forEach(item => {
            binsInStorage.push({
              product: this.storageData.product_in_stock_request.product.id,
              product_in_bin: this.storageData.id,
              storage_unit: item.storage.id,
              stored_quantity: Number(item.inbounded_quantity)
            })
          })
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/products-in-storage-unit/', 'post', binsInStorage, true,
            () => {
              this.$emit('loadProductInBin')
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    }
  }
}
</script>

<style lang="scss">
.add-products {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
div.stock-request {
  .vs-input-number {
    * {
      padding: 0px !important;
    }
  }
  .vs-input-number--input {
    font-size: 15px !important;
  }
  .vs-input-number {
    margin: 0px 10px!important;
  }
  .vs-dialog{
    max-width: 800px !important;
  }
}
div.stock-request-input-number {
  button {
    width: 15px !important;
    height: 15px !important;
    
  }
}
.input-number-disabled {
  button.btn-plus {
    pointer-events: none;
    background: rgba(0,0,0,.5);
    opacity: 0.5;
  }
}
</style>